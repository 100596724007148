<template>
  <div class="modal-container d-flex align-items-center justify-content-center" id="create-user">
    <div class="modal-body">
      <div class="form-container w-50">
        <div class="confirm-text justify-content-center">
          You are about to delete {{selectedUser.length}} user{{selectedUser > 1 ? 's' : ''}}:
          <ul>
            <li v-for="user of selectedUser" :key="user">
              {{user.email}}
            </li>
          </ul>
        </div>
        <div class="d-flex gap-3 justify-content-center">
          <button class="btn btn-primary" @click="confirmDelete">
            Confirm Delete
          </button>
          <button class="btn btn-secondary" @click="closeModal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api.js";
export default {
  setup() {
    
  },
  emits: ["closeModal", "updateUser", "updateSelected"],
  props: {
    selectedUser: Array
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    confirmDelete() {
      this.selectedUser.map(async (user) => {
        try {
          var result = api.deleteUser(user.uid)
          return result
        } catch(e) {
          alert(e.message.response.data);
          return false;
        }
      })
      alert(`Successfully deleted user${this.selectedUser.length > 1 ? 's' : ''}`)
      this.$emit("updateSelected",[])
      this.$emit("updateUser")
      this.closeModal();
    }
  }
}
</script>

<style scoped>
.modal-container {
  background: rgba(128, 128, 128, 0.5);
  z-index: 2;
}
.modal-body {
  background: white;
  margin: 0 30px;
  border-radius: 20px;
  padding: 30px;
}
.close {
  cursor: pointer;
}
.form-container {
  width: fit-content;
  margin: 0 auto;
}
.confirm-text {
  width: fit-content;
  margin: 0 auto;
}
</style>