import axios, { AxiosError } from "axios";

const headers = {
  "X-Api-Key": "DNSKn24hq96wY1U7UfKZk6iE35OodZYw6VxB7nO9",
};

const url = "https://dyp92n6okg.execute-api.ap-southeast-1.amazonaws.com/Prod/";

const getUsers = async (table) => {
  try {
    const result = await axios.post(
      url + "users/get",
      { database: table },
      { headers: headers }
    );
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const getDocsUsers = async () => {
  try {
    const result = await axios.post(
      url + "/docs-users/get",
      {},
      { headers: headers }
    );
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const getGroups = async () => {
  try {
    const result = await axios.post(
      url + "/groups/get",
      {},
      { headers: headers }
    );
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const createUser = async (data) => {
  try {
    if ("group" in data) {
      data["group"] = data["group"].split(";");
    }

    const result = await axios.post(url + "users/create", data, {
      headers: headers,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const createDocsUser = async (data) => {
  try {
    const result = await axios.post(url + "/docs-users/create", data, {
      headers: headers,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const createGroup = async (data) => {
  try {
    const result = await axios.post(url + "/groups/create", data, {
      headers: headers,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const deleteUser = async (userId) => {
  try {
    const result = await axios.post(
      url + "users/delete",
      { userId: userId },
      { headers: headers }
    );
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const deleteDocsUser = async (userId) => {
  try {
    const result = await axios.post(
      url + "/docs-users/delete",
      { userId: userId },
      { headers: headers }
    );
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const deleteGroup = async (groupName) => {
  try {
    const result = await axios.post(
      url + "/groups/delete",
      { GroupName: groupName },
      { headers: headers }
    );
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const editUser = async (data) => {
  try {
    if ("group" in data) {
      data["group"] = data["group"].split(";");
    }
    const result = await axios.post(url + "users/edit", data, {
      headers: headers,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const editDocsUser = async (data) => {
  try {
    const result = await axios.post(url + "/docs-users/edit", data, {
      headers: headers,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

const editGroup = async (data) => {
  try {
    const result = await axios.post(url + "/groups/edit", data, {
      headers: headers,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw new AxiosError(e);
  }
};

export default {
  getUsers,
  createUser,
  deleteUser,
  editUser,
  getDocsUsers,
  createDocsUser,
  deleteDocsUser,
  editDocsUser,
  getGroups,
  createGroup,
  deleteGroup,
  editGroup,
};
