<template>
  <div>
    <div
      class="modal-container d-flex align-items-center justify-content-center"
      id="create-user"
    >
      <div class="modal-body">
        <div class="d-flex justify-content-end mb-4 close" @click="closeModal">
          X
        </div>
        <div class="form-container w-75">
          <form v-on:submit="createUser">
            <div class="section">
              Account {{ table.toUpperCase() }} Information
            </div>
            <!-- 1. Email -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Email</div>
              <input
                v-model="form.email"
                class="form-control"
                placeholder="email@panoptic.ai"
              />
            </div>

            <!-- 3. Plan Type -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-shrink">planType</div>
              <select v-model="form.planType" class="form-select">
                <option disabled value="">Please select one</option>
                <option value="pro">pro</option>
                <option value="free">free</option>
              </select>
            </div>
            <!-- 4. Plan Expiry Date -->
            <div class="d-flex mb-2">
              <div class="w-50">planExpiryDate</div>
              <div class="w-100">
                <input
                  class="form-check-input"
                  type="radio"
                  value="date"
                  name="flexRadioDefault"
                  id="flexRadioDefault"
                  v-model="expiryType"
                />
                <label class="form-check-label ps-3" for="flexRadioDefault1">
                  Date
                </label>
                <input
                  :disabled="expiryType !== 'date'"
                  type="date"
                  class="form-control date my-3"
                  id="planExpiryDateInput"
                  v-model="form.planExpiryDate"
                />
                <input
                  class="form-check-input"
                  type="radio"
                  value="unlimited"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  v-model="expiryType"
                />
                <label class="form-check-label ps-3" for="flexRadioDefault1">
                  Unlimited
                </label>
              </div>
            </div>

            <!-- 4. Groups -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Group</div>
              <input
                v-model="form.group"
                class="form-control"
                placeholder="demo-users"
              />
            </div>

            <!-- Submit button -->
            <div class="d-flex justify-content-center gap-4">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="Object.keys(changedField).length == 0"
              >
                Edit Information
              </button>
              <button @click="reset" class="btn btn-secondary">Reset</button>
            </div>

            <!-- Information box -->
            <div class="d-flex justify-content-center mt-4 mb-2 gap-2">
              <div class="w-75">
                <div id="text-console" />
              </div>
              <img
                src="../../assets/copy.png"
                alt="copy"
                id="copy-button"
                class="my-auto"
                v-on:click="copyToClipBoard"
              />
              <label id="copy-label" style="color: red"></label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api.js";
export default {
  setup() {},
  props: {
    user: Object,
    table: String,
  },
  emits: ["closeModal", "updateUser"],
  methods: {
    async createUser(e) {
      if (e) {
        e.preventDefault();
      }

      // check for errors
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          if (key === "email") {
            if (!this.form.email.includes("@")) {
              alert(`please fill in a valid Email !!`);
              return false;
            }
          } else if (key === "planExpiryDate") {
            if (this.expiryType == "date" && value == "unlimited") {
              alert(`please choose date for the planExpiryDate`);
              return false;
            }
          }
        } else {
          alert(`please fill in a valid input for [ ${key} ] !!`);
          return false;
        }
      }
      this.changedField.userId = this.user.uid;
      try {
        var res = await api.editUser(this.changedField);
        alert("Successfully modified user's information");
      } catch (e) {
        alert(e.message.response.data);
        return false;
      }
      this.$emit("updateUser");
      this.generateMessage();
      return res;
    },
    generateMessage() {
      let text = `email: ${this.form.email}<br/>planType: ${this.form.planType}<br/>planExpiryDate: ${this.form.planExpiryDate}`;
      document.getElementById("text-console").innerHTML = text;
    },
    async generatePassword(e) {
      if (e) {
        e.preventDefault();
      }
      this.form.password = Math.random().toString(36).substring(2, 10);
    },
    async copyToClipBoard(e) {
      if (e) {
        e.preventDefault();
      }

      let text = document.getElementById("text-console");
      // text.select();

      if (text.innerHTML) {
        text = text.innerHTML.replaceAll("<br>", "\n");
        navigator.clipboard.writeText(text);
        document.getElementById("copy-label").innerHTML = "copied!";

        setTimeout(() => {
          document.getElementById("copy-label").innerHTML = "";
        }, 2000);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    reset(e) {
      e.preventDefault();
      this.form = Object.create(this.user);
    },
  },
  mounted() {
    this.form = Object.create(this.user);
    if (this.form.planExpiryDate == "unlimited") {
      this.expiryType = "unlimited";
    } else {
      this.expiryType = "date";
    }
    this.form.group = this.user.group ? this.user.group.join(",") : "";
  },
  data() {
    return {
      form: {},
      expiryType: "",
      changedField: {},
    };
  },
  watch: {
    expiryType() {
      if (this.expiryType == "unlimited") {
        this.form.planExpiryDate = "unlimited";
      }
    },
    "form.planExpiryDate"(val) {
      if (val == "unlimited") {
        this.expiryType = "unlimited";
      }
    },
    "form.email"(val) {
      if (val !== this.user.email) {
        this.changedField.email = val;
      } else {
        delete this.changedField.email;
      }
    },
    "form.group"(val) {
      if (val !== (this.user.group ? this.user.group.join(",") : "")) {
        this.changedField.group = val;
      } else {
        delete this.changedField.group;
      }
    },
    plan: {
      deep: true,
      handler: function (val) {
        if (
          val.planType !== this.user.planType ||
          val.planExpiryDate !== this.user.planExpiryDate
        ) {
          this.changedField[this.table + "PlanType"] = val.planType;
          this.changedField[this.table + "PlanExpiryDate"] = val.planExpiryDate;
        } else if (
          val.planType == this.user.planType &&
          val.planExpiryDate == this.user.planExpiryDate
        ) {
          delete this.changedField[this.table + "PlanType"];
          delete this.changedField[this.table + "PlanExpiryDate"];
        }
      },
    },
  },
  computed: {
    plan() {
      return {
        planType: this.form.planType,
        planExpiryDate: this.form.planExpiryDate,
      };
    },
  },
};
</script>

<style scoped>
.modal-container {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.modal-body {
  position: absolute;
  width: 80%;
  background: white;
  border-radius: 20px;
  padding: 30px;
  padding-bottom: 50px;
}
.form-select {
  margin-left: 6px;
}
.input-group {
  margin-left: -3px;
}
.form-container {
  width: fit-content;
  margin: 0 auto;
}
#text-console {
  margin: auto auto;
  text-align: left;
  width: 100%;
  height: 100%;
  border: solid;
}
#copy-button {
  cursor: pointer;
}
.close {
  cursor: pointer;
}
.section {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 10px;
}
</style>
