<template>
  <div>
    <div
      class="modal-container d-flex align-items-center justify-content-center"
      id="create-user"
    >
      <div class="modal-body my-auto">
        <div class="d-flex justify-content-end mb-2 close" @click="closeModal">
          X
        </div>
        <div class="form-container w-75">
          <form v-on:submit="createUser">
            <div class="section">Account Information</div>
            <!-- 1. Email -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Email</div>
              <input
                v-model="form.email"
                class="form-control"
                placeholder="email@panoptic.ai"
              />
            </div>

            <!-- 2. Password -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Password</div>
              <div class="input-group">
                <input
                  v-model="form.password"
                  type="text"
                  class="form-control"
                  placeholder="Password"
                />
                <button class="btn btn-secondary" @click="generatePassword">
                  Generate Password
                </button>
              </div>
            </div>

            <!-- 3. B2B Plan Type -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">B2B planType</div>
              <select v-model="form.b2bPlanType" class="form-select">
                <option disabled value="">Please select one</option>
                <option value="pro">pro</option>
                <option value="free">free</option>
              </select>
            </div>

            <!-- 4. B2B Plan Expiry Date -->
            <div class="d-flex mb-2">
              <div class="w-50">B2B planExpiryDate</div>
              <div class="w-100">
                <input
                  class="form-check-input"
                  type="radio"
                  value="date"
                  name="b2bExpiry"
                  id="flexRadioDefault"
                  v-model="b2bExpiryType"
                />
                <label class="form-check-label ps-3" for="b2bExpiry">
                  Date
                </label>
                <input
                  :disabled="b2bExpiryType !== 'date'"
                  type="date"
                  class="form-control date my-3"
                  id="planExpiryDateInput"
                  v-model="form.b2bPlanExpiryDate"
                />
                <input
                  class="form-check-input"
                  type="radio"
                  value="unlimited"
                  name="b2bExpiry"
                  id="flexRadioDefault1"
                  v-model="b2bExpiryType"
                />
                <label class="form-check-label ps-3" for="b2bExpiry">
                  Unlimited
                </label>
              </div>
            </div>

            <!-- 5. B2C Plan Type -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">B2C planType</div>
              <select v-model="form.b2cPlanType" class="form-select">
                <option disabled value="">Please select one</option>
                <option value="pro">pro</option>
                <option value="free">free</option>
              </select>
            </div>

            <!-- 6. B2C Plan Expiry Date -->
            <div class="d-flex mb-2">
              <div class="w-50">B2C planExpiryDate</div>
              <div class="w-100">
                <input
                  class="form-check-input"
                  type="radio"
                  value="date"
                  name="b2cExpiry"
                  id="flexRadioDefault"
                  v-model="b2cExpiryType"
                />
                <label class="form-check-label ps-3" for="b2cExpiry">
                  Date
                </label>
                <input
                  :disabled="b2cExpiryType !== 'date'"
                  type="date"
                  class="form-control date my-3"
                  id="planExpiryDateInput"
                  v-model="form.b2cPlanExpiryDate"
                />
                <input
                  class="form-check-input"
                  type="radio"
                  value="unlimited"
                  name="b2cExpiry"
                  id="flexRadioDefault1"
                  v-model="b2cExpiryType"
                />
                <label class="form-check-label ps-3" for="b2cExpiry">
                  Unlimited
                </label>
              </div>
            </div>

            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Group</div>
              <div class="input-group">
                <input
                  v-model="form.group"
                  type="text"
                  class="form-control"
                  placeholder="demo-users"
                />
              </div>
            </div>

            <!-- Submit button -->
            <div class="d-flex justify-content-center mb-4 gap-4">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="!Object.values(form).every((item) => item)"
              >
                Create User
              </button>
              <button type="reset" @click="reset" class="btn btn-secondary">
                Reset
              </button>
            </div>
          </form>

          <div class="section pb-0">Information Message</div>
          <div class="subtext">
            The selected web information (link and password) will be included in
            the copied message
          </div>

          <div class="d-flex mb-4">
            <div class="w-50 flex-grow">Webapp Info</div>
            <div class="input-group">
              <select v-model="web" class="form-select" :disabled="!created">
                <option disabled value="">Please select one</option>
                <option value="webapp">Webapp</option>
                <option value="vitals">Vitals</option>
                <option value="lify">Lify</option>
                <option value="aia">AIA</option>
              </select>
              <button
                class="btn btn-secondary"
                @click="generateMessage"
                :disabled="!web"
              >
                Generate Message
              </button>
            </div>
          </div>

          <!-- Information box -->
          <div class="d-flex justify-content-center mb-2 gap-2">
            <div class="w-75">
              <div id="text-console" />
            </div>
            <img
              src="../../assets/copy.png"
              alt="copy"
              id="copy-button"
              class="my-auto"
              v-on:click="copyToClipBoard"
            />
            <label id="copy-label" style="color: red"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api.js";

export default {
  setup() {},
  emits: ["closeModal", "updateUser"],
  methods: {
    async createUser(e) {
      if (e) {
        e.preventDefault();
      }

      // check for errors
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          if (key === "email") {
            if (!this.form.email.includes("@")) {
              alert(`please fill in a valid Email !!`);
              return false;
            }
          }
        } else {
          alert(`please fill in a valid input for [ ${key} ] !!`);
          return false;
        }
      }
      try {
        var result = await api.createUser(this.form);
        alert("Successfully created user");
      } catch (e) {
        alert(e.message.response.data);
        return false;
      }
      this.$emit("updateUser");

      this.created = true;
      return result;
    },
    reset() {
      this.created = false;
    },
    generateMessage() {
      let text = `email: ${this.form.email}<br/>password: ${this.form.password}<br/>`;

      if (this.webappInfo[this.web].type == "b2b") {
        text = `${text}planType: ${this.form.b2bPlanType}<br/>planExpiryDate: ${this.form.b2bPlanExpiryDate}`;
      } else if (this.webappInfo[this.web].type == "b2c") {
        text = `${text}planType: ${this.form.b2cPlanType}<br/>planExpiryDate: ${this.form.b2cPlanExpiryDate}`;
      }
      document.getElementById("text-console").innerHTML = text;
    },
    async generatePassword(e) {
      if (e) {
        e.preventDefault();
      }
      var uppers = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowers = "abcdefghijklmnopqrstuvwxyz";
      var numbers = "0123456789";
      var specials = "_-|@.,?/!~#$%^&*(){}[]+=";
      var charClasses = [uppers, lowers, numbers, specials];
      var minLen = charClasses.length;
      function chooseRandom(x) {
        var i = Math.floor(Math.random() * x.length);
        return typeof x === "string" ? x.substr(i, 1) : x[i];
      }
      // Define the function to actually generate a random string.
      var maxLen = 8;
      maxLen = maxLen || 36;
      if (maxLen < minLen) {
        throw new Error("length must be >= " + minLen);
      }
      do {
        // Append a random char from a random char class.
        var str = "",
          usedClasses = {},
          charClass;
        while (str.length < maxLen) {
          charClass = chooseRandom(charClasses);
          usedClasses[charClass] = true;
          str += chooseRandom(charClass);
        }
        // Ensure we have picked from every char class.
      } while (Object.keys(usedClasses).length !== charClasses.length);
      this.form.password = str;
    },
    async copyToClipBoard(e) {
      if (e) {
        e.preventDefault();
      }

      let text = document.getElementById("text-console");
      // text.select();

      if (text.innerHTML) {
        text = text.innerHTML.replaceAll("<br>", "\n");
        text = `website: https://${this.webappInfo[this.web].link}\npassword: ${
          this.webappInfo[this.web].password
        }\n\n${text}`;

        navigator.clipboard.writeText(text);
        document.getElementById("copy-label").innerHTML = "copied!";

        setTimeout(() => {
          document.getElementById("copy-label").innerHTML = "";
        }, 2000);
      }
    },
    closeModal() {
      (this.form = {
        email: null,
        password: null,
        b2bPlanType: "pro",
        b2bPlanExpiryDate: null,
        b2cPlanType: "pro",
        b2cPlanExpiryDate: null,
      }),
        this.reset();
      this.$emit("closeModal");
    },
  },
  watch: {
    b2bExpiryType() {
      if (this.b2bExpiryType == "unlimited") {
        this.form.b2bPlanExpiryDate = "unlimited";
      }
    },
    b2cExpiryType() {
      if (this.b2cExpiryType == "unlimited") {
        this.form.b2cPlanExpiryDate = "unlimited";
      }
    },
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        b2bPlanType: "pro",
        b2bPlanExpiryDate: null,
        b2cPlanType: "pro",
        b2cPlanExpiryDate: null,
      },
      created: false,
      b2bExpiryType: "",
      b2cExpiryType: "",
      webappInfo: {
        webapp: {
          link: "webapp.panoptic.ai",
          password: "wvslogin",
          type: "b2c",
        },
        vitals: {
          link: "vitals.panoptic.ai",
          password: "wvslogin",
          type: "b2b",
        },
        lify: {
          link: "lify.tea.panoptic.ai",
          password: "lifywellness",
          type: "b2b",
        },
        aia: {
          link: "aia.tda.panoptic.ai",
          password: "panopticai",
          type: "b2b",
        },
      },
      web: undefined,
    };
  },
};
</script>

<style scoped>
.modal-container {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.modal-body {
  max-height: 100vh;
  position: absolute;
  width: 80%;
  background: white;
  border-radius: 20px;
  padding: 30px;
  padding-bottom: 50px;
  overflow: scroll;
}
.form-select {
  margin-left: 6px;
}
.input-group {
  margin-left: -3px;
}
.form-container {
  width: fit-content;
  margin: 0 auto;
}
#text-console {
  margin: auto auto;
  text-align: left;
  width: 100%;
  height: 100%;
  border: solid;
}
#copy-button {
  cursor: pointer;
}
.close {
  cursor: pointer;
}
#copy-label {
  margin: auto 0;
}
.copy {
  width: 20%;
}
.date {
  position: relative;
  left: 30px;
  width: calc(100% - 30px);
}
.section {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
}
.subtext {
  font-size: 12px;
  padding-bottom: 20px;
}
</style>
