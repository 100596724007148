import { User } from "./user-definitions";

export enum cloudBackend {
  aws = 0,
}

export interface UserManagerInterface {
  currentUser: User | undefined;
  login(backend: cloudBackend): Promise<void>;
  ready(backend: cloudBackend): Promise<void>;
  logout(): Promise<void>;
}
