<template>
  <div>
    <div
      class="modal-container d-flex align-items-center justify-content-center"
    >
      <div class="modal-body">
        <div class="d-flex justify-content-end mb-4 close" @click="closeModal">
          X
        </div>
        <div class="form-container w-75">
          <form v-on:submit="editUser">
            <!-- 1. Email -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Email</div>
              <input
                v-model="form.email"
                class="form-control"
                placeholder="email@panoptic.ai"
              />
            </div>
            <!-- Submit button -->
            <div class="d-flex justify-content-center gap-4">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="Object.keys(changedField).length == 0"
              >
                Edit Information
              </button>
              <button @click="reset" class="btn btn-secondary">Reset</button>
            </div>

            <!-- Information box -->
            <div class="d-flex justify-content-center mt-4 mb-2 gap-2">
              <div class="w-75">
                <div id="text-console" />
              </div>
              <img
                src="../../assets/copy.png"
                alt="copy"
                id="copy-button"
                class="my-auto"
                v-on:click="copyToClipBoard"
              />
              <label id="copy-label" style="color: red"></label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api.js";

export default {
  setup() {},
  props: {
    user: Object,
  },
  emits: ["closeModal", "updateAccount"],
  methods: {
    async editUser(e) {
      if (e) {
        e.preventDefault();
      }

      // check for errors
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          if (key === "email") {
            if (!this.form.email.includes("@")) {
              alert(`please fill in a valid Email !!`);
              return false;
            }
          }
        } else {
          alert(`please fill in a valid input for [ ${key} ] !!`);
          return false;
        }
      }
      this.changedField.userId = this.user.uid;
      try {
        var res = await api.editDocsUser(this.changedField);
        alert("Successfully modified user's information");
      } catch (e) {
        alert(e.message.response.data);
        return false;
      }
      this.$emit("updateAccount");
      this.generateMessage();
      return res;
    },
    generateMessage() {
      let text = `email: ${this.form.email}`;
      document.getElementById("text-console").innerHTML = text;
    },
    async generatePassword(e) {
      if (e) {
        e.preventDefault();
      }
      this.form.password = Math.random().toString(36).substring(2, 10);
    },
    async copyToClipBoard(e) {
      if (e) {
        e.preventDefault();
      }

      let text = document.getElementById("text-console");

      if (text.innerHTML) {
        text = text.innerHTML.replaceAll("<br>", "\n");
        navigator.clipboard.writeText(text);
        document.getElementById("copy-label").innerHTML = "copied!";

        setTimeout(() => {
          document.getElementById("copy-label").innerHTML = "";
        }, 2000);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    reset(e) {
      e.preventDefault();
      this.form = Object.create(this.user);
    },
  },
  mounted() {
    this.form = Object.create(this.user);
  },
  data() {
    return {
      form: {},
      expiryType: "",
      changedField: {},
    };
  },
  watch: {
    "form.email"(val) {
      if (val !== this.user.email) {
        this.changedField.email = val;
      } else {
        delete this.changedField.email;
      }
    },
  },
};
</script>

<style scoped>
.modal-container {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.modal-body {
  position: absolute;
  width: 80%;
  background: white;
  border-radius: 20px;
  padding: 30px;
  padding-bottom: 50px;
}
.form-select {
  margin-left: 6px;
}
.input-group {
  margin-left: -3px;
}
.form-container {
  width: fit-content;
  margin: 0 auto;
}
#text-console {
  margin: auto auto;
  text-align: left;
  width: 100%;
  height: 100%;
  border: solid;
}
#copy-button {
  cursor: pointer;
}
.close {
  cursor: pointer;
}
</style>
