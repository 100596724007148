import type { User } from "./user-definitions";

export class BaseUser implements User {
  cloudInfo?: any;
  name: string | undefined;
  userId?: string | undefined;
  login(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  logout(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  ready(): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
