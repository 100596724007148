<template>
  <div>
    <div
      class="modal-container d-flex align-items-center justify-content-center"
      id="create-user"
    >
      <div class="modal-body my-auto">
        <div class="d-flex justify-content-end mb-2 close" @click="closeModal">
          X
        </div>
        <div class="form-container w-75">
          <form v-on:submit="createAccount">
            <div class="section">Account Information</div>
            <!-- 1. Email -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Email</div>
              <input
                v-model="form.email"
                class="form-control"
                placeholder="email@panoptic.ai"
              />
            </div>

            <!-- 2. Password -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Password</div>
              <div class="input-group">
                <input
                  v-model="form.password"
                  type="text"
                  class="form-control"
                  placeholder="Password"
                />
                <button class="btn btn-secondary" @click="generatePassword">
                  Generate Password
                </button>
              </div>
            </div>

            <!-- Submit button -->
            <div class="d-flex justify-content-center mb-4 gap-4">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="
                  !Object.values(form).every((item) => item) || this.waiting
                "
              >
                Create User
              </button>
              <button type="reset" @click="reset" class="btn btn-secondary">
                Reset
              </button>
            </div>
          </form>

          <div class="section pb-0">Information Message</div>

          <div class="d-flex mb-4">
            <div class="w-50 flex-grow">Webapp Info</div>
            <div class="input-group">
              <button
                class="btn btn-secondary"
                @click="generateMessage"
                :disabled="!created"
              >
                Generate Message
              </button>
            </div>
          </div>

          <!-- Information box -->
          <div class="d-flex justify-content-center mb-2 gap-2">
            <div class="w-75">
              <div id="text-console" />
            </div>
            <img
              src="../../assets/copy.png"
              alt="copy"
              id="copy-button"
              class="my-auto"
              v-on:click="copyToClipBoard"
            />
            <label id="copy-label" style="color: red"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api.js";

export default {
  setup() {},
  emits: ["closeModal", "updateAccount"],
  methods: {
    async createAccount(e) {
      if (e) {
        e.preventDefault();
      }

      // check for errors
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          if (key === "email") {
            if (!this.form.email.includes("@")) {
              alert(`please fill in a valid Email !!`);
              return false;
            }
          }
        } else {
          alert(`please fill in a valid input for [ ${key} ] !!`);
          return false;
        }
      }
      try {
        this.waiting = true;
        var result = await api.createDocsUser(this.form);
        alert("Successfully created user");
        this.waiting = false;
      } catch (e) {
        alert(e.message.response.data);
        this.waiting = false;
        return false;
      }
      this.$emit("updateAccount");

      this.created = true;
      return result;
    },
    async generatePassword(e) {
      if (e) {
        e.preventDefault();
      }
      var uppers = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowers = "abcdefghijklmnopqrstuvwxyz";
      var numbers = "0123456789";
      var specials = "_-|@.,?/!~#$%^&*(){}[]+=";
      var charClasses = [uppers, lowers, numbers, specials];
      var minLen = charClasses.length;
      function chooseRandom(x) {
        var i = Math.floor(Math.random() * x.length);
        return typeof x === "string" ? x.substr(i, 1) : x[i];
      }
      // Define the function to actually generate a random string.
      var maxLen = 8;
      maxLen = maxLen || 36;
      if (maxLen < minLen) {
        throw new Error("length must be >= " + minLen);
      }
      do {
        // Append a random char from a random char class.
        var str = "",
          usedClasses = {},
          charClass;
        while (str.length < maxLen) {
          charClass = chooseRandom(charClasses);
          usedClasses[charClass] = true;
          str += chooseRandom(charClass);
        }
        // Ensure we have picked from every char class.
      } while (Object.keys(usedClasses).length !== charClasses.length);
      this.form.password = str;
    },
    generateMessage() {
      let text = `email: ${this.form.email}<br/>password: ${this.form.password}`;

      document.getElementById("text-console").innerHTML = text;
    },
    async copyToClipBoard(e) {
      if (e) {
        e.preventDefault();
      }

      let text = document.getElementById("text-console");
      // text.select();

      if (text.innerHTML) {
        text = text.innerHTML.replaceAll("<br>", "\n");
        text = `website: https://docs.panoptic.ai\n${text}`;

        navigator.clipboard.writeText(text);
        document.getElementById("copy-label").innerHTML = "copied!";

        setTimeout(() => {
          document.getElementById("copy-label").innerHTML = "";
        }, 2000);
      }
    },
    reset() {
      this.created = false;
      this.form = {
        email: null,
        password: null,
      };
    },
    closeModal() {
      (this.form = {
        email: null,
        password: null,
      }),
        this.reset();
      this.$emit("closeModal");
    },
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      created: false,
      waiting: false,
    };
  },
};
</script>

<style scoped>
.modal-container {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.modal-body {
  max-height: 100vh;
  position: absolute;
  min-width: 80%;
  background: white;
  border-radius: 20px;
  padding: 1.5em;
}
.form-select {
  margin-left: 6px;
}
.input-group {
  margin-left: -3px;
}
.form-container {
  max-width: 95%;
  margin: 0 auto;
}
#text-console {
  margin: auto auto;
  text-align: left;
  width: 100%;
  height: 100%;
  border: solid;
}
#copy-button {
  cursor: pointer;
}
.close {
  cursor: pointer;
}
#copy-label {
  margin: auto 0;
}
.copy {
  width: 20%;
}
.date {
  position: relative;
  left: 30px;
  width: calc(100% - 30px);
}
.section {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
}
.subtext {
  font-size: 12px;
  padding-bottom: 20px;
}
</style>
