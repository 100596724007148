import { createApp } from "vue";
import App from "./Main.vue";
import i18n from "./i18n";
import "bootstrap/dist/css/bootstrap.min.css"; // using bootstrap
import { createRouter, createWebHashHistory } from "vue-router";
import AccountsPage from "./views/accounts/AccountsPage.vue";
import DocsAccountsPage from "./views/docs-accounts/DocsAccountsPage.vue";
import GroupsPage from "./views/groups/GroupsPage.vue";
import LoginPage from "./views/login/aws-login.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import "./output.css";

/* AWS config */
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { userManager } from "./components/user/user-manager";
Amplify.configure(awsconfig);

const basicRoutes = [
  {
    path: "/accounts",
    name: "users",
    component: AccountsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/docs-accounts",
    name: "docs-users",
    component: DocsAccountsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/groups",
    name: "groups",
    component: GroupsPage,
    meta: {
      requiresAuth: true,
    },
  },
  { path: "/login", name: "login", component: LoginPage },
  { path: "/", redirect: "/login" },
];

const routes = basicRoutes;

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    next(); // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (to.meta && to.meta.requiresAuth === false) {
    next(); // requires auth is explicitly set to false
  } else if (
    userManager.currentUser &&
    userManager.currentUser.cloudInfo !== undefined
  ) {
    next(); // i'm logged in. carry on
  } else {
    next({ name: "login" }); // always put your redirect as the default case
  }
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.component("EasyDataTable", Vue3EasyDataTable);
app.mount("#app");
