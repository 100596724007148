<template>
    <select class="form-select" v-model="$i18n.locale" id="locale-selector">
        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
            {{ locale }}
        </option>
    </select>
</template>

<script>

export default {
    name: "localeSelector",
    
}

</script>

<style scoped>

#locale-selector {
}

</style>