<template>
  <div class="container-fluid mt-3">
    <div class="home-page-head">
      <div class="title">{{ appTitle }}</div>
    </div>
    <div class="home-page-body container-fluid">
      <h1>DOCS ACCOUNTS</h1>
      <div class="table-container">
        <div class="d-flex my-3">
          <button
            type="button"
            class="btn btn-secondary"
            @click="createAccount(true)"
          >
            Create User
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="selectedAccount.length == 0"
            @click="deleteAccount(true)"
          >
            Delete User
          </button>
          <div class="input-group">
            <select name="" id="" class="form-select" v-model="filter">
              <option value="uid">UID</option>
              <option value="email">Email</option>
            </select>
            <input
              type="text"
              class="form-control w-50"
              placeholder="Search keyword"
              v-model="keyword"
            />
            <button
              class="btn btn-secondary"
              type="button"
              id="button-addon2"
              @click="search"
            >
              Search
            </button>
          </div>
        </div>

        <docs-accounts-table
          @updateSelected="updateSelected"
          @updateAccount="fetchData"
          :filter="filter"
          :keyword="keyword"
          :allUsers="users"
          :selectedDelete="selectedAccount"
        />
      </div>
    </div>
    <delete-docs-account-modal
      class="modals"
      :selectedAccount="selectedAccount"
      @closeModal="deleteAccount(false)"
      @updateAccount="fetchData"
      @updateSelected="updateSelected"
      v-if="showDelete"
    />
    <create-docs-account
      class="modals"
      @closeModal="createAccount(false)"
      @updateAccount="fetchData"
      v-if="createAccountModal"
    />
  </div>
</template>
<script>
import DocsAccountsTable from "./DocsAccountsTable.vue";
import CreateDocsAccount from "./CreateDocsAccount.vue";
import DeleteDocsAccountModal from "./DeleteModal.vue";
import api from "../../api.js";

export default {
  name: "DocsAccountsPage",
  components: {
    DocsAccountsTable,
    CreateDocsAccount,
    DeleteDocsAccountModal,
  },
  props: {},
  setup() {},
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      createAccountModal: false,
      showDelete: false,
      selectedAccount: [],
      users: [],
      filter: "email",
      keyword: "",
    };
  },
  methods: {
    async fetchData() {
      try {
        var res = await api.getDocsUsers();
      } catch (e) {
        alert(e.message.response.data);
        return false;
      }
      this.users = res.data;
    },
    updateSelected(selected) {
      this.selectedAccount = selected;
    },
    createAccount(val) {
      this.createAccountModal = val;
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    deleteAccount(val) {
      this.showDelete = val;
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    openModal() {
      window.scrollTo(0, 0);
      document.body.style.height = "100vh";
      document.body.style.overflowY = "hidden";
    },
    closeModal() {
      document.body.style.height = "auto";
      document.body.style.overflowY = "auto";
    },
  },
  computed: {
    appTitle() {
      return `${this.$root.appTitle} ${this.$root.appVersion}`;
    },
  },
};
</script>

<style scoped>
.title {
  width: 60%;
  margin: auto auto;
  text-align: center;
  color: red;
  font-weight: bold;
  text-decoration: underline;
  font-size: x-large;
}

.d-flex {
  gap: 20px;
}

.modals {
  background: rgba(128, 128, 128, 0.5);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
</style>
