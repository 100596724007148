<template>
  <div class="table-wrap">
    <table class="table">
      <thead>
        <tr>
          <th>
            <label class="checkbox-wrap checkbox-primary">
              <input
                type="checkbox"
                @change="allSelected"
                :id="'all'"
                :checked="selectedDelete.length > 0"
              />
              <span class="checkmark"></span>
            </label>
          </th>
          <th>
            <div class="d-flex gap-1 align-items-center">
              <div class="flex-column">
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/sort-up.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('GroupName', 'asc')"
                    />
                  </span>
                </label>
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/caret-down.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('GroupName', 'desc')"
                    />
                  </span>
                </label>
              </div>
              <div class="w-100">Group Name</div>
            </div>
          </th>
          <th>
            <div class="d-flex gap-1 align-items-center">
              <div class="flex-column">
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/sort-up.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('description', 'asc')"
                    />
                  </span>
                </label>
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/caret-down.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('description', 'desc')"
                    />
                  </span>
                </label>
              </div>
              <div class="w-100">Description</div>
            </div>
          </th>
          <th>
            <div class="d-flex gap-1 align-items-center">
              <div class="flex-column">
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/sort-up.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('apiKeys', 'asc')"
                    />
                  </span>
                </label>
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/caret-down.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('apiKeys', 'desc')"
                    />
                  </span>
                </label>
              </div>
              <div class="w-100">API Keys</div>
            </div>
          </th>
          <th>
            <div class="d-flex gap-1 align-items-center">
              <div class="flex-column">
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/sort-up.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('DBAccessRights', 'asc')"
                    />
                  </span>
                </label>
                <label class="sort-label">
                  <input type="radio" name="sort" />
                  <span class="seatButton">
                    <img
                      src="../../assets/caret-down.svg"
                      alt=""
                      class="sort-button"
                      @click="sort('DBAccessRights', 'desc')"
                    />
                  </span>
                </label>
              </div>
              <div class="w-100">Access Permissions</div>
            </div>
          </th>
          <th class="text-center">&nbsp;</th>
        </tr>
      </thead>
      <tbody v-for="group of groups" :key="group">
        <tr class="alert" role="alert">
          <td>
            <label class="checkbox-wrap checkbox-primary">
              <input
                type="checkbox"
                @change="rowClicked"
                :id="group.GroupName"
                :checked="isChecked(group)"
              />
              <span class="checkmark"></span>
            </label>
          </td>
          <td :id="group.GroupName">{{ group.GroupName }}</td>
          <td :id="group.GroupName">{{ group.Description }}</td>
          <td :id="group.GroupName">
            {{ group.apiKeys ? group.apiKeys.join(", ") : "" }}
          </td>
          <td :id="group.GroupName">
            {{ group.DBAccessRights ? group.DBAccessRights.join(", ") : "" }}
          </td>
          <td :id="group.GroupName">
            <button
              @click="editGroupInfo"
              class="text-nowrap btn btn-light edit-group-button m-1"
              :id="group.GroupName"
            >
              Edit Information
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center pb-3" v-if="allGroups">
      <div
        v-for="n in Array(Math.round(filteredGroups.length / 10)).keys()"
        :key="n"
        class="text-center"
      >
        <button
          type="button"
          v-if="page !== 0 && n == 0"
          class="pagination btn btn-light"
          @click="goToPage(page - 1)"
        >
          {{ "<" }}
        </button>
        <button
          type="button"
          v-if="n == page - 2 && n !== 0"
          class="pagination btn btn-light"
        >
          {{ "..." }}
        </button>
        <button
          type="button"
          v-if="n >= page - 2 && n <= page + 2"
          :class="`pagination btn ${
            page === n ? 'btn-secondary' : 'btn-light'
          }`"
          @click="goToPage(n)"
        >
          {{ n + 1 }}
        </button>
        <button
          type="button"
          v-if="
            n == page + 2 && n !== Math.round(filteredGroups.length / 10) - 1
          "
          class="pagination btn btn-light"
        >
          {{ "..." }}
        </button>
        <button
          type="button"
          v-if="
            page !== Math.round(filteredGroups.length / 10) - 1 &&
            n == Math.round(filteredGroups.length / 10) - 1
          "
          class="pagination btn btn-light"
          @click="goToPage(page + 1)"
        >
          {{ ">" }}
        </button>
      </div>
    </div>
  </div>
  <edit-group
    class="edit-group-modal"
    v-if="selectedEdit !== undefined"
    :group="selectedEdit"
    @closeModal="closeModal"
    @updateGroup="this.$emit('updateGroup')"
  />
</template>
<script>
import EditGroup from "./EditGroup.vue";

export default {
  components: {
    EditGroup,
  },
  emits: ["updateSelected", "updateGroup"],
  props: {
    allGroups: Object,
    filter: String,
    keyword: String,
    selectedDelete: Array,
  },
  data() {
    return {
      selectedEdit: undefined,
      filteredGroups: this.allGroups,
      groups: [],
      page: 0,
    };
  },
  watch: {
    searchInput() {
      this.search();
    },
    allGroups() {
      this.filteredGroups = this.allGroups;
    },
    filteredGroups() {
      this.groups = this.filteredGroups.slice(
        this.page * 10,
        (this.page + 1) * 10
      );
    },
  },
  mounted() {
    this.groups = this.allGroups.slice(this.page * 10, (this.page + 1) * 10);
  },
  computed: {
    searchInput() {
      return [this.keyword, this.filter];
    },
  },
  methods: {
    search() {
      this.filteredGroups = this.allGroups.filter((group) => {
        return group[this.filter].includes(this.keyword);
      });
    },
    isChecked(group) {
      return this.selectedDelete.find(
        (selected) => selected.GroupName == group.GroupName
      );
    },
    findGroup(groupName) {
      return this.allGroups.filter((group) => {
        return group.GroupName == groupName;
      })[0];
    },
    editGroupInfo(e) {
      this.selectedEdit = this.findGroup(e.target.id);
      window.scrollTo(0, 0);
      document.body.style.height = "100vh";
      document.body.style.overflowY = "hidden";
    },
    closeModal() {
      this.selectedEdit = undefined;
      this.selectedPassword = undefined;
      document.body.style.height = "auto";
      document.body.style.overflowY = "auto";
    },
    rowClicked(e) {
      const group = this.findGroup(e.target.id);
      if (
        e.target.checked &&
        !this.selectedDelete.find(
          (selected) => selected.GroupName == group.GroupName
        )
      ) {
        this.$emit("updateSelected", [...this.selectedDelete, group]);
      } else if (!e.target.checked) {
        this.$emit(
          "updateSelected",
          this.selectedDelete.filter((x) => x.GroupName !== group.GroupName)
        );
      }
    },
    allSelected(e) {
      if (e.target.checked) {
        this.$emit("updateSelected", this.filteredGroups);
      } else {
        this.$emit("updateSelected", []);
      }
    },
    goToPage(n) {
      this.page = n;
      this.groups = this.filteredGroups.slice(
        this.page * 10,
        (this.page + 1) * 10
      );
    },
    sort(attr, dir) {
      if (dir == "asc") {
        this.filteredGroups = this.filteredGroups.sort((a, b) =>
          a[attr] > b[attr] ? 1 : b[attr] > a[attr] ? -1 : 0
        );
      } else {
        this.filteredGroups = this.filteredGroups.sort((b, a) =>
          a[attr] > b[attr] ? 1 : b[attr] > a[attr] ? -1 : 0
        );
      }
      this.groups = this.filteredGroups.slice(
        this.page * 10,
        (this.page + 1) * 10
      );
    },
  },
  setup() {
    return {};
  },
};
</script>
<style scoped>
.table-wrap {
  width: 100%;
  overflow-x: scroll;
}

.table thead {
  background: rgb(102, 177, 173);
}

td,
th {
  vertical-align: middle;
}
.table tbody tr {
  margin-bottom: 10px;
}

.edit-group-modal {
  background: rgba(128, 128, 128, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.edit-group-button {
  text-align: center;
  cursor: pointer;
}

.sort {
  width: 10px;
}
.sort-button {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;
}
th {
  vertical-align: middle;
}
.sort-button:focus {
  opacity: 1;
}
.sort-label input {
  display: none;
}
.sort-label .seatButton {
  opacity: 0.3;
}
.sort-label input:checked + .seatButton {
  opacity: 1;
}

.pagination {
  padding: 3px 10px;
  margin: 0 4px;
  display: inline;
}
</style>
