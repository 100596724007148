import { Auth, Hub, Logger } from "aws-amplify";
import { BaseUser } from "./user-base";
import type { User } from "./user-definitions";

const logger = new Logger("Logger", "INFO");

export class AwsUser extends BaseUser implements User {
  declare cloudInfo?: any;
  declare name: string | undefined;
  declare userId?: string | undefined;

  async userHasLoggedIn(resolve: Function) {
    this.cloudInfo = await Auth.currentUserInfo();
    this.userId = this.cloudInfo.username;
    resolve();
  }
  async login(): Promise<void> {
    const user = await Auth.currentUserInfo();
    if (user) {
      this.cloudInfo = user;
      this.userId = this.cloudInfo.username;
    } else {
      return new Promise((resolve) => {
        Hub.listen("auth", (data) => {
          const { payload } = data;

          switch (payload.event) {
            case "signIn":
              logger.info("user signed in");
              this.userHasLoggedIn(resolve);
              break;
            case "signUp":
              logger.info("user signed up");
              this.userHasLoggedIn(resolve);
              break;
          }
        });
      });
    }
  }
  async logout(): Promise<void> {
    try {
      await Auth.signOut({ global: true });
      console.log("logged out ", this);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();
    let success = false;

    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      success = true;
      console.log(
        `%c < cognito > user.changePassword @ ${this.userId}`,
        "color: green"
      );
    } catch (err) {
      console.log(
        `%c < cognito > Failed to change password\n`,
        "color: red",
        err
      );
    }
    return success;
  }
  async ready(): Promise<void> {
    const user = await Auth.currentUserInfo();
    if (user) this.cloudInfo = user;
  }
}
