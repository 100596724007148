<template>
  <div>
    <div
      class="modal-container d-flex align-items-center justify-content-center"
      id="create-group"
    >
      <div class="modal-body my-auto">
        <div class="d-flex justify-content-end mb-2 close" @click="closeModal">
          X
        </div>
        <div class="form-container w-75">
          <form v-on:submit="createGroup">
            <div class="section">Group Information</div>
            <!-- 1. Group Name -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Group Name</div>
              <input
                v-model="form.GroupName"
                type="text"
                class="form-control"
                placeholder="group-name DO NOT USE SPACE, NAME UNCHANGEABLE"
              />
            </div>

            <!-- 2. Description -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Description</div>
              <div class="input-group">
                <input
                  v-model="form.Description"
                  type="text"
                  class="form-control"
                  placeholder="Description"
                />
              </div>
            </div>

            <!-- 3. API Keys -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">API Keys</div>
              <div class="input-group">
                <input
                  v-model="form.APIKeys"
                  type="text"
                  class="form-control"
                  placeholder="key1,key2,key3 - if nothing put []"
                />
              </div>
            </div>

            <!-- 4. DB Access Rights -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">DB Access Rights</div>
              <div class="input-group">
                <input
                  v-model="form.DBAccessRights"
                  type="text"
                  class="form-control"
                  placeholder="db1,db2,db3 - if nothing put []"
                />
              </div>
            </div>
            <!-- Submit button -->
            <div class="d-flex justify-content-center mb-4 gap-4">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="
                  !Object.values(form).every((item) => item) || this.waiting
                "
              >
                Create Group
              </button>
              <button type="reset" @click="reset" class="btn btn-secondary">
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api.js";

export default {
  setup() {},
  emits: ["closeModal", "updateGroup"],
  methods: {
    async createGroup(e) {
      if (e) {
        e.preventDefault();
      }

      // check for errors
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          if (key === "GroupName") {
            if (this.form.GroupName.includes(" ")) {
              alert(`Do not use whitespace in Group Name!!`);
              return false;
            }
          }
        } else {
          alert(`please fill in a valid input for [ ${key} ] !!`);
          return false;
        }
      }

      // cleanup data
      if (this.form["APIKeys"] !== "[]") {
        this.form["APIKeys"] = this.form["APIKeys"].split(",");
      } else this.form["APIKeys"] = [];

      if (this.form["DBAccessRights"] !== "[]") {
        this.form["DBAccessRights"] = this.form["DBAccessRights"].split(",");
      } else this.form["DBAccessRights"] = [];

      try {
        this.waiting = true;
        var result = await api.createGroup(this.form);
        alert("Successfully created group");
        this.waiting = false;
        this.closeModal();
      } catch (e) {
        alert(e.message.response.data);
        this.waiting = false;
        return false;
      }
      this.$emit("updateGroup");

      this.created = true;
      return result;
    },
    reset() {
      this.created = false;
      this.form = {
        GroupName: null,
        Description: null,
      };
    },
    closeModal() {
      (this.form = {
        GroupName: null,
        Description: null,
      }),
        this.reset();
      this.$emit("closeModal");
    },
  },
  data() {
    return {
      form: {
        GroupName: null,
        Description: null,
      },
      created: false,
      waiting: false,
    };
  },
};
</script>

<style scoped>
.modal-container {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.modal-body {
  max-height: 100vh;
  position: absolute;
  min-width: 80%;
  background: white;
  border-radius: 20px;
  padding: 1.5em;
}
.form-select {
  margin-left: 6px;
}
.input-group {
  margin-left: -3px;
}
.form-container {
  max-width: 95%;
  margin: 0 auto;
}
#text-console {
  margin: auto auto;
  text-align: left;
  width: 100%;
  height: 100%;
  border: solid;
}
#copy-button {
  cursor: pointer;
}
.close {
  cursor: pointer;
}
#copy-label {
  margin: auto 0;
}
.copy {
  width: 20%;
}
.date {
  position: relative;
  left: 30px;
  width: calc(100% - 30px);
}
.section {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
}
.subtext {
  font-size: 12px;
  padding-bottom: 20px;
}
</style>
