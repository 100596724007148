<template>
  <navigationBarVue />
  <router-view />
  <div id="copyright">{{ t("copyright") }}</div>
</template>

<script>
import { useI18n } from "vue-i18n";
import navigationBarVue from "./components/navigationBar.vue";

export default {
  name: "App",
  components: {
    navigationBarVue,
  },
  setup() {
    let appTitle = "Vitals™️ User Account Admin";
    let appVersion = "1.0.0";
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      appTitle: appTitle,
      appVersion: appVersion,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* margin-top: 60px; */
}

#copyright {
  position: relative;
  padding: 5px;
  width: 100%;
  height: 4.5vh;
  text-align: center;
  /* margin: auto auto; */
}
</style>
