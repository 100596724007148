<template>
  <div class="container-fluid mt-3">
    <div class="home-page-body container-fluid">
      <h1>GROUPS</h1>
      <div class="table-container">
        <div class="d-flex my-3">
          <button
            type="button"
            class="btn btn-secondary"
            @click="createGroup(true)"
          >
            Create Group
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="selectedGroup.length == 0"
            @click="deleteGroup(true)"
          >
            Delete Group
          </button>
          <div class="input-group">
            <select name="" id="" class="form-select" v-model="filter">
              <option value="GroupName">GroupName</option>
            </select>
            <input
              type="text"
              class="form-control w-50"
              placeholder="Search keyword"
              v-model="keyword"
            />
            <button
              class="btn btn-secondary"
              type="button"
              id="button-addon2"
              @click="search"
            >
              Search
            </button>
          </div>
        </div>
        <groups-table
          @updateGroup="fetchData"
          @updateSelected="updateSelected"
          :allGroups="groups"
          :filter="filter"
          :keyword="keyword"
          :selectedDelete="selectedGroup"
        />
      </div>
    </div>
    <create-group-modal
      class="modals"
      @closeModal="createGroup(false)"
      @updateGroup="fetchData"
      v-if="createGroupModal"
    />
    <delete-group-modal
      class="modals"
      :selectedGroup="selectedGroup"
      @closeModal="deleteGroup(false)"
      @updateGroup="fetchData"
      @updateSelected="updateSelected"
      v-if="showDelete"
    />
  </div>
</template>

<script>
import GroupsTable from "./GroupsTable.vue";
import CreateGroupModal from "./CreateGroupModal.vue";
import DeleteGroupModal from "./DeleteModal.vue";
import api from "../../api.js";

export default {
  name: "GroupsPage",
  props: {},
  components: {
    GroupsTable,
    CreateGroupModal,
    DeleteGroupModal,
  },
  setup() {},
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      createGroupModal: false,
      showDelete: false,
      selectedGroup: [],
      groups: [],
      filter: "GroupName",
      keyword: "",
    };
  },
  methods: {
    async fetchData() {
      try {
        var res = await api.getGroups();
      } catch (e) {
        alert(e.message.response.data);
        return false;
      }
      this.groups = res.data.Groups;
    },
    updateSelected(selected) {
      this.selectedGroup = selected;
    },
    createGroup(val) {
      this.createGroupModal = val;
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    deleteGroup(val) {
      this.showDelete = val;
      console.log(val);
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    openModal() {
      window.scrollTo(0, 0);
      document.body.style.height = "100vh";
      document.body.style.overflowY = "hidden";
    },
    closeModal() {
      document.body.style.height = "auto";
      document.body.style.overflowY = "auto";
    },
  },
};
</script>

<style scoped>
.title {
  width: 60%;
  margin: auto auto;
  text-align: center;
  color: red;
  font-weight: bold;
  text-decoration: underline;
  font-size: x-large;
}

.d-flex {
  gap: 20px;
}

.modals {
  background: rgba(128, 128, 128, 0.5);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
</style>
