<template>
  <div>
    <div
      class="modal-container d-flex align-items-center justify-content-center"
    >
      <div class="modal-body">
        <div class="d-flex justify-content-end mb-4 close" @click="closeModal">
          X
        </div>
        <div class="form-container w-75">
          <form v-on:submit="editPassword">
            <div class="section">Modify Account Password</div>
            <!-- 1. Email -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Email</div>
              <input
                v-model="form.email"
                class="form-control"
                placeholder="email@panoptic.ai"
                disabled
              />
            </div>

            <!-- 2. Password -->
            <div class="d-flex mb-2">
              <div class="w-50 flex-grow">Password</div>
              <div class="input-group">
                <input
                  v-model="form.password"
                  type="text"
                  class="form-control"
                  placeholder="Password"
                />
                <button class="btn btn-secondary" @click="generatePassword">
                  Generate Password
                </button>
              </div>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                name="permanent-password"
                type="checkbox"
                value="true"
                v-model="forceChange"
                id="permanent-password"
              />
              <label for="permanent-password" class="form-check-label"
                >Make password permanent? (if yes, user doesn't have to change
                password on login)</label
              >
            </div>

            <!-- Submit button -->
            <div class="d-flex justify-content-center gap-4">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="!form.password"
              >
                Edit Information
              </button>
              <button @click="reset" class="btn btn-secondary">Reset</button>
            </div>

            <!-- Information box -->
            <div class="d-flex justify-content-center mt-4 mb-2 gap-2">
              <div class="w-75">
                <div id="text-console" />
              </div>
              <img
                src="../../assets/copy.png"
                alt="copy"
                id="copy-button"
                class="my-auto"
                v-on:click="copyToClipBoard"
              />
              <label id="copy-label" style="color: red"></label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api.js";

export default {
  setup() {},
  props: {
    user: Object,
  },
  emits: ["closeModal", "updateAccount"],
  methods: {
    async editPassword(e) {
      if (e) {
        e.preventDefault();
      }

      // check for errors
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          if (key === "email") {
            if (!this.form.email.includes("@")) {
              alert(`please fill in a valid Email !!`);
              return false;
            }
          }
        } else {
          alert(`please fill in a valid input for [ ${key} ] !!`);
          return false;
        }
      }

      const data = {
        userId: this.form.uid,
        password: this.form.password,
        isPermanentPassword: this.forceChange,
      };
      try {
        var result = await api.editDocsUser(data);
        alert("Successfully modified user's password");
      } catch (e) {
        alert(e.message.response.data);
        return false;
      }
      this.$emit("updateUser");

      this.generateMessage();
      return result;
    },
    async generatePassword(e) {
      if (e) {
        e.preventDefault();
      }
      var uppers = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowers = "abcdefghijklmnopqrstuvwxyz";
      var numbers = "0123456789";
      var specials = "_-|@.,?/!~#$%^&*(){}[]+=";
      var charClasses = [uppers, lowers, numbers, specials];
      var minLen = charClasses.length;
      function chooseRandom(x) {
        var i = Math.floor(Math.random() * x.length);
        return typeof x === "string" ? x.substr(i, 1) : x[i];
      }
      // Define the function to actually generate a random string.
      var maxLen = 8;
      maxLen = maxLen || 36;
      if (maxLen < minLen) {
        throw new Error("length must be >= " + minLen);
      }
      do {
        // Append a random char from a random char class.
        var str = "",
          usedClasses = {},
          charClass;
        while (str.length < maxLen) {
          charClass = chooseRandom(charClasses);
          usedClasses[charClass] = true;
          str += chooseRandom(charClass);
        }
        // Ensure we have picked from every char class.
      } while (Object.keys(usedClasses).length !== charClasses.length);
      this.form.password = str;
    },
    generateMessage() {
      let text = `email: ${this.form.email}<br/>password: ${this.form.password}<br/>`;

      document.getElementById("text-console").innerHTML = text;
    },
    async copyToClipBoard(e) {
      if (e) {
        e.preventDefault();
      }

      let text = document.getElementById("text-console");
      // text.select();

      if (text.innerHTML) {
        text = text.innerHTML.replaceAll("<br>", "\n");
        if (this.forceChange) {
          text =
            text +
            "\nThe above password will expire in 7 days. Please login and change your password before it expires.";
        }

        navigator.clipboard.writeText(text);
        document.getElementById("copy-label").innerHTML = "copied!";

        setTimeout(() => {
          document.getElementById("copy-label").innerHTML = "";
        }, 2000);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    reset(e) {
      e.preventDefault();
      this.form = this.user;
    },
  },
  mounted() {
    Object.assign(this.form, this.user);
  },
  data() {
    return {
      form: {},
      forceChange: true,
    };
  },
};
</script>

<style scoped>
.modal-container {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.modal-body {
  position: absolute;
  width: 80%;
  background: white;
  border-radius: 20px;
  padding: 30px;
  padding-bottom: 50px;
}
.form-select {
  margin-left: 6px;
}
.input-group {
  margin-left: -3px;
}
.form-container {
  width: fit-content;
  margin: 0 auto;
}
#text-console {
  margin: auto auto;
  text-align: left;
  width: 100%;
  height: 100%;
  border: solid;
}
#copy-button {
  cursor: pointer;
}
.close {
  cursor: pointer;
}
.section {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 10px;
}
</style>
