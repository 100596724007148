<template>
  <div>
    <div class="table-wrap">
      <table class="table">
        <thead>
          <tr>
            <th>
              <label class="checkbox-wrap checkbox-primary">
                <input
                  type="checkbox"
                  @change="allSelected"
                  :id="'all'"
                  :checked="selectedDelete.length > 0"
                />
                <span class="checkmark"></span>
              </label>
            </th>
            <th>
              <div class="d-flex gap-1 align-items-center">
                <div class="flex-column">
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/sort-up.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('uid', 'asc')"
                      />
                    </span>
                  </label>
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/caret-down.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('uid', 'desc')"
                      />
                    </span>
                  </label>
                </div>
                <div class="w-100">UID</div>
              </div>
            </th>
            <th>
              <div class="d-flex gap-1 align-items-center">
                <div class="flex-column">
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/sort-up.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('email', 'asc')"
                      />
                    </span>
                  </label>
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/caret-down.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('email', 'desc')"
                      />
                    </span>
                  </label>
                </div>
                <div class="w-100">Email</div>
              </div>
            </th>
            <th>
              <div class="d-flex gap-1 align-items-center">
                <div class="flex-column">
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/sort-up.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('planType', 'asc')"
                      />
                    </span>
                  </label>
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/caret-down.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('planType', 'desc')"
                      />
                    </span>
                  </label>
                </div>
                <div class="w-100">Plan Type</div>
              </div>
            </th>
            <th>
              <div class="d-flex gap-1 align-items-center">
                <div class="flex-column">
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/sort-up.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('planExpiryDate', 'asc')"
                      />
                    </span>
                  </label>
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/caret-down.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('planExpiryDate', 'desc')"
                      />
                    </span>
                  </label>
                </div>
                <div class="w-100">Plan Expiry Date</div>
              </div>
            </th>
            <th>
              <div class="d-flex gap-1 align-items-center">
                <div class="flex-column">
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/sort-up.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('group', 'asc')"
                      />
                    </span>
                  </label>
                  <label class="sort-label">
                    <input type="radio" name="sort" />
                    <span class="seatButton">
                      <img
                        src="../../assets/caret-down.svg"
                        alt=""
                        class="sort-button"
                        @click="sort('group', 'desc')"
                      />
                    </span>
                  </label>
                </div>
                <div class="w-100">Group</div>
              </div>
            </th>
            <th class="text-center">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-for="user of users" :key="user">
          <tr class="alert" role="alert">
            <td>
              <label class="checkbox-wrap checkbox-primary">
                <input
                  type="checkbox"
                  @change="rowClicked"
                  :id="user.uid"
                  :checked="isChecked(user)"
                />
                <span class="checkmark"></span>
              </label>
            </td>
            <td :id="user.uid">{{ user.uid }}</td>
            <td :id="user.uid">{{ user.email }}</td>
            <td :id="user.uid" class="text-center">{{ user.planType }}</td>
            <td :id="user.uid" class="text-center text-nowrap">
              {{ user.planExpiryDate }}
            </td>
            <td :id="user.uid" class="text-center text-nowrap">
              {{ user.group ? user.group.join(",") : "" }}
            </td>
            <td :id="user.uid">
              <button
                @click="editUserInfo"
                class="text-nowrap btn btn-light edit-user-button m-1"
                :id="user.uid"
              >
                Edit Information
              </button>
              <button
                @click="modifyPassword"
                class="text-nowrap btn btn-light edit-user-button m-1"
                :id="user.uid"
              >
                Modify Password
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center pb-3" v-if="allUsers">
        <div
          v-for="n in Array(Math.round(filteredUsers.length / 10)).keys()"
          :key="n"
          class="text-center"
        >
          <button
            type="button"
            v-if="page !== 0 && n == 0"
            class="pagination btn btn-light"
            @click="goToPage(page - 1)"
          >
            {{ "<" }}
          </button>
          <button
            type="button"
            v-if="n == page - 2 && n !== 0"
            class="pagination btn btn-light"
          >
            {{ "..." }}
          </button>
          <button
            type="button"
            v-if="n >= page - 2 && n <= page + 2"
            :class="`pagination btn ${
              page === n ? 'btn-secondary' : 'btn-light'
            }`"
            @click="goToPage(n)"
          >
            {{ n + 1 }}
          </button>
          <button
            type="button"
            v-if="
              n == page + 2 && n !== Math.round(filteredUsers.length / 10) - 1
            "
            class="pagination btn btn-light"
          >
            {{ "..." }}
          </button>
          <button
            type="button"
            v-if="
              page !== Math.round(filteredUsers.length / 10) - 1 &&
              n == Math.round(filteredUsers.length / 10) - 1
            "
            class="pagination btn btn-light"
            @click="goToPage(page + 1)"
          >
            {{ ">" }}
          </button>
        </div>
      </div>
    </div>
    <edit-user
      class="edit-user-modal"
      v-if="selectedEdit !== undefined"
      :user="selectedEdit"
      @closeModal="closeModal"
      :table="table"
      @updateUser="this.$emit('updateUser')"
    />
    <modify-password
      class="edit-user-modal"
      v-if="selectedPassword !== undefined"
      :user="selectedPassword"
      @closeModal="closeModal"
      :table="table"
      @updateUser="this.$emit('updateUser')"
    />
  </div>
</template>

<script>
import EditUser from "./EditAccount.vue";
import ModifyPassword from "./ModifyPassword.vue";

export default {
  setup() {
    return {};
  },
  emits: ["updateSelected", "updateUser"],
  components: {
    EditUser,
    ModifyPassword,
  },
  props: {
    keyword: String,
    filter: String,
    allUsers: Object,
    table: String,
    selectedDelete: Array,
  },
  data() {
    return {
      selectedEdit: undefined,
      filteredUsers: this.allUsers,
      selectedPassword: undefined,
      users: [],
      page: 0,
    };
  },
  watch: {
    searchInput() {
      this.search();
    },
    allUsers() {
      this.filteredUsers = this.allUsers;
    },
    filteredUsers() {
      this.users = this.filteredUsers.slice(
        this.page * 10,
        (this.page + 1) * 10
      );
    },
  },
  mounted() {
    this.users = this.allUsers.slice(this.page * 10, (this.page + 1) * 10);
  },
  computed: {
    searchInput() {
      return [this.keyword, this.filter];
    },
  },
  methods: {
    isChecked(user) {
      return this.selectedDelete.find((selected) => selected.uid == user.uid);
    },
    search() {
      this.filteredUsers = this.allUsers.filter((user) => {
        return user[this.filter].includes(this.keyword);
      });
    },
    rowClicked(e) {
      const user = this.findUser(e.target.id);
      if (
        e.target.checked &&
        !this.selectedDelete.find((selected) => selected.uid == user.uid)
      ) {
        this.$emit("updateSelected", [...this.selectedDelete, user]);
      } else if (!e.target.checked) {
        this.$emit(
          "updateSelected",
          this.selectedDelete.filter((x) => x.uid !== user.uid)
        );
      }
    },
    allSelected(e) {
      if (e.target.checked) {
        this.$emit("updateSelected", this.filteredUsers);
      } else {
        this.$emit("updateSelected", []);
      }
    },
    findUser(userId) {
      return this.allUsers.filter((user) => {
        return user.uid == userId;
      })[0];
    },
    editUserInfo(e) {
      this.selectedEdit = this.findUser(e.target.id);
      window.scrollTo(0, 0);
      document.body.style.height = "100vh";
      document.body.style.overflowY = "hidden";
    },
    modifyPassword(e) {
      this.selectedPassword = this.findUser(e.target.id);
      window.scrollTo(0, 0);
      document.body.style.height = "100vh";
      document.body.style.overflowY = "hidden";
    },
    closeModal() {
      this.selectedEdit = undefined;
      this.selectedPassword = undefined;
      document.body.style.height = "auto";
      document.body.style.overflowY = "auto";
    },
    sort(attr, dir) {
      if (dir == "asc") {
        this.filteredUsers = this.filteredUsers.sort((a, b) =>
          a[attr] > b[attr] ? 1 : b[attr] > a[attr] ? -1 : 0
        );
      } else {
        this.filteredUsers = this.filteredUsers.sort((b, a) =>
          a[attr] > b[attr] ? 1 : b[attr] > a[attr] ? -1 : 0
        );
      }
      this.users = this.filteredUsers.slice(
        this.page * 10,
        (this.page + 1) * 10
      );
    },
    goToPage(n) {
      this.page = n;
      this.users = this.filteredUsers.slice(
        this.page * 10,
        (this.page + 1) * 10
      );
    },
  },
};
</script>

<style scoped>
.table-wrap {
  width: 100%;
  overflow-x: scroll;
}

.table thead {
  background: rgb(102, 177, 173);
}

td,
th {
  padding: 30px;
  vertical-align: middle;
}

.table tbody tr {
  margin-bottom: 10px;
}

.table tbody th,
.table tbody td {
  font-size: 1em;
  background: #fff;
}
.edit-user-modal {
  background: rgba(128, 128, 128, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.edit-user-button {
  text-align: center;
  cursor: pointer;
}
.sort {
  width: 10px;
}
.sort-button {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;
}
th {
  vertical-align: middle;
}
.sort-button:focus {
  opacity: 1;
}

.pagination {
  padding: 3px 10px;
  margin: 0 4px;
  display: inline;
}

.sort-label input {
  display: none;
}
.sort-label .seatButton {
  opacity: 0.3;
}
.sort-label input:checked + .seatButton {
  opacity: 1;
}
</style>
