<template>
  <div class="container-fluid text-center">
    <authenticator :hide-sign-up="true" :login-mechanisms="['email']" />
  </div>
  <div></div>
</template>

<script>
import { userManager } from "../../components/user/user-manager.ts";
import { cloudBackend } from "../../components/user/user-manager-definitions.ts";
import { Auth, Hub, Logger } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { ref } from "vue";

const logger = new Logger("Logger", "INFO");

export default {
  name: "login-page",
  components: {
    Authenticator,
  },
  setup() {
    const auth = useAuthenticator();
    const isReady = ref(false);

    return {
      auth,
      isReady,
    };
  },
  async mounted() {
    let currentCloudBackend = cloudBackend.aws;
    console.log("aws-login mounted", userManager);

    if (userManager.currentUser) {
      let currentUser = userManager.currentUser;
      // check if the user has logged in or not
      if (currentUser && currentUser.cloudInfo) {
        console.log("already logged in: ", currentUser);
        this.$router.push("/users");
        return;
      }
      // log user in
      switch (currentUser.userType) {
        case cloudBackend.local: {
          break;
        }
        case cloudBackend.aws: {
          await userManager.login(cloudBackend.aws);
          console.log("login done");
          break;
        }
      }

      // Login complete, redirect to home
      console.log("logged in: ", userManager.currentUser);
      this.$router.push("/accounts");
      return;
    } else {
      await userManager.ready(cloudBackend.aws);
      console.log("waiting for user login");
      this.isReady = true;
      await userManager.login(currentCloudBackend);
      this.$router.push("/accounts");
    }
  },
  methods: {
    async login() {
      let user = await Auth.currentUserInfo();
      if (user) {
        this.cloudInfo = user;
        this.userId = this.cloudInfo.username;
      } else {
        return new Promise((resolve) => {
          Hub.listen("auth", (data) => {
            const { payload } = data;

            switch (payload.event) {
              case "signIn":
                logger.info("user signed in");
                this.userHasLoggedIn(resolve);
                break;
              case "signUp":
                logger.info("user signed up");
                this.userHasLoggedIn(resolve);
                break;
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped>
#panoptic-ai {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 600px;
  max-width: 90%;
}
#logo {
  width: 80px;
  margin: 2em;
}
.app-title {
  font-size: x-large;
  color: #696969;
  text-shadow: 1px 1px #acacac;
}

.container-fluid {
  min-height: 100vh;
}

.copyright {
  position: absolute;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>
