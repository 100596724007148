<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <span class="navbar-brand">
        <img
          alt="PanopticAI logo"
          id="logo"
          src="../assets/panopticaiLogo.png"
        />
      </span>
      <div class="navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="/#/accounts">Accounts</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#/docs-accounts">Docs Accounts</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#/groups">Groups</a>
          </li>
        </ul>
        <span class="d-flex">
          <localeSelectorVue />
          <button type="button" class="btn btn-primary" @click="this.logout()">
            Logout
          </button>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
import localeSelectorVue from "./localeSelector.vue";
import { userManager } from "../components/user/user-manager.ts";

export default {
  name: "navigationBar",
  components: {
    localeSelectorVue,
  },
  methods: {
    async logout() {
      await userManager.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
/* .navi-bar {
  display: inline-flex;
  height: 60px;
} */

#nav-buttons {
  display: flex;
}

#logo {
  height: 2em;
}
</style>
