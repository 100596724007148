<template>
  <div class="container-fluid mt-3">
    <div class="home-page-head">
      <div class="title">{{ appTitle }}</div>
    </div>
    <div class="home-page-body container-fluid">
      <h1>ACCOUNTS</h1>
      <div class="table-container">
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-secondary"
            @click="createUser(true)"
          >
            Create User
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="selectedUser.length == 0"
            @click="deleteUser(true)"
          >
            Delete User
          </button>
          <div class="input-group">
            <select name="" id="" class="form-select" v-model="filter">
              <option value="uid">UID</option>
              <option value="email">Email</option>
              <option value="planType">PlanType</option>
              <option value="planExpiryDate">PlanExpiryDate</option>
            </select>
            <input
              type="text"
              class="form-control w-50"
              placeholder="Search keyword"
              v-model="keyword"
            />
            <button
              class="btn btn-secondary"
              type="button"
              id="button-addon2"
              @click="search"
            >
              Search
            </button>
          </div>
        </div>
        <div class="d-flex my-3">
          <button
            :class="`btn flex-shrink ${
              this.table == 'b2b' ? 'btn-primary' : 'btn-light'
            }`"
            @click="changeTable('b2b')"
          >
            B2B
          </button>
          <button
            :class="`btn flex-shrink ${
              this.table == 'b2c' ? 'btn-primary' : 'btn-light'
            }`"
            @click="changeTable('b2c')"
          >
            B2C
          </button>
        </div>
        <accounts-table
          ref="table"
          @updateSelected="updateSelected"
          :filter="filter"
          :keyword="keyword"
          :allUsers="users"
          :table="table"
          @updateUser="fetchData"
          :selectedDelete="selectedUser"
        />
      </div>
    </div>
    <delete-modal
      class="modals"
      :selectedUser="selectedUser"
      v-if="showDelete"
      @closeModal="deleteUser(false)"
      @updateUser="fetchData"
      @updateSelected="updateSelected"
    />
    <create-user
      class="modals"
      @closeModal="createUser(false)"
      v-if="createUserModal"
      @updateUser="fetchData"
    />
  </div>
</template>

<script>
import AccountsTable from "./AccountsTable.vue";
import CreateUser from "./CreateAccount.vue";
import DeleteModal from "./DeleteModal.vue";
import api from "../../api.js";
// for debug
// import users from "./mock.json"

export default {
  name: "AccountsPage",
  props: {},
  setup() {},
  components: {
    AccountsTable,
    CreateUser,
    DeleteModal,
  },
  computed: {
    appTitle() {
      return `${this.$root.appTitle} ${this.$root.appVersion}`;
    },
  },
  methods: {
    createUser(val) {
      this.createUserModal = val;
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    openModal() {
      window.scrollTo(0, 0);
      document.body.style.height = "100vh";
      document.body.style.overflowY = "hidden";
    },
    closeModal() {
      document.body.style.height = "auto";
      document.body.style.overflowY = "auto";
    },
    updateSelected(selected) {
      this.selectedUser = selected;
    },
    deleteUser(val) {
      this.showDelete = val;
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    search() {
      this.$refs.table.search();
    },
    changeTable(table) {
      this.table = table;
      this.fetchData();
    },
    async fetchData() {
      try {
        var res = await api.getUsers(this.table);
      } catch (e) {
        alert(e.message.response.data);
        return false;
      }
      this.users = res.data;
    },
  },
  data() {
    return {
      createUserModal: false,
      selectedUser: [],
      users: [],
      filter: "uid",
      keyword: "",
      table: "b2b",
      showDelete: false,
    };
  },
  async created() {
    await this.fetchData();
    // for debug
    // this.users = users[this.table]
  },
};
</script>

<style>
body {
  color: #2c3e50;
  background-color: lightgray;
}
</style>

<style scoped>
.title {
  width: 60%;
  margin: auto auto;
  text-align: center;
  color: red;
  font-weight: bold;
  text-decoration: underline;
  font-size: x-large;
}
#copyright {
  margin: auto auto;
}
.d-flex {
  gap: 20px;
}
.btn {
  white-space: nowrap;
}
.modals {
  background: rgba(128, 128, 128, 0.5);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-page-body {
  min-height: calc(95vh - 100px);
}
</style>
