import { User } from "./user-definitions";
import { cloudBackend, UserManagerInterface } from "./user-manager-definitions";
import { AwsUser } from "./user-aws";

class UserManager implements UserManagerInterface {
  currentUser: User | undefined;
  constructor() {
    console.log("create user manager");
  }
  async login(backend: cloudBackend): Promise<void> {
    if (this.currentUser) {
      switch (backend) {
        case cloudBackend.aws: {
          await this.currentUser.login();
          break;
        }
      }
    }
  }
  async ready(backend: cloudBackend): Promise<void> {
    switch (backend) {
      case cloudBackend.aws: {
        this.currentUser = new AwsUser();
        break;
      }
    }
    if (this.currentUser) {
      await this.currentUser.ready();
    }
  }
  async logout(): Promise<void> {
    await this.currentUser?.logout();
    this.currentUser = undefined;
    console.log("%cLogout success", "color: blue");
  }
}

const userManager = new UserManager();

export { userManager };
